import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService, SettingService } from '../../../services/Index.Service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  public myForm: FormGroup;
  ID: FormControl;
  Name: FormControl;
  Email: FormControl;
  Phone: FormControl;
  Pincode: FormControl;
  Address: FormControl;
  AlternateEmail: FormControl;
  AlternatePhone: FormControl;

  public mySettingForm: FormGroup;
  BillTitle: FormControl;
  BillWarning: FormControl;
  IsShowSignature: FormControl;
  rdo: FormGroup;
  constructor(private router: Router, private _fb: FormBuilder, private userService: UserService, private settingService: SettingService) { }

  ngOnInit() {
    this.get();
  }

  get() {
    this.userService.Get().subscribe(Response => {
      if (Response.status) {
        this.setForm(Response.data);
      }
    }, error => {
    });
  }

  getsetting() {
    this.settingService.Get().subscribe(Response => {
      if (Response.status) {
        this.setSettingForm(Response.data);
      }
    }, error => {
    });
  }

  profile() {
    this.userService.Update(this.myForm.value).subscribe(Response => {
      if (Response.status) {
        localStorage.setItem('user', JSON.stringify(Response.data));
        alert("Your Profile Successfully Updated");
      }
    });
  }

  setting() {
    this.settingService.Manage(this.mySettingForm.value).subscribe(Response => {
      if (Response.status) {
        alert("Your Setting Successfully Updated");
      }
    });
  }

  setForm(model: any) {
    this.setSettingForm(null);
    this.ID = new FormControl(!model ? 0 : model.id);
    this.Name = new FormControl(!model ? null : model.name, [Validators.required]);
    this.Email = new FormControl(!model ? null : model.email);
    this.Pincode = new FormControl(!model ? null : model.pinCode, [Validators.required]);
    this.Phone = new FormControl(!model ? null : model.phone);
    this.Address = new FormControl(!model ? null : model.address, [Validators.required]);
    this.AlternateEmail = new FormControl(!model ? null : model.alternateEmail, [Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]);
    this.AlternatePhone = new FormControl(!model ? null : model.alternatePhone, [Validators.minLength(10), Validators.maxLength(10)]);

    this.myForm = this._fb.group({
      ID: this.ID,
      Name: this.Name,
      Email: this.Email,
      Pincode: this.Pincode,
      Phone: this.Phone,
      Address: this.Address,
      AlternateEmail: this.AlternateEmail,
      AlternatePhone: this.AlternatePhone,
    });
  }

  setSettingForm(model: any) {
    this.ID = new FormControl(!model ? 0 : model.id);
    this.BillTitle = new FormControl(!model ? null : model.billTitle);
    this.BillWarning = new FormControl(!model ? null : model.billWarning);
    this.IsShowSignature = new FormControl(!model ? true : model.isShowSignature);

    this.mySettingForm = this._fb.group({
      ID: this.ID,
      BillTitle: this.BillTitle,
      BillWarning: this.BillWarning,
      IsShowSignature: this.IsShowSignature,
    });


  }
}
