import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../../services/Index.Service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public myForm: FormGroup;
  Email: FormControl;
  Password: FormControl;
  errorMessage: string;
  constructor(private router: Router, private _fb: FormBuilder, private auth: AuthenticationService) {
  }

  ngOnInit() {
    document.getElementsByTagName('body')[0].className = "hold-transition login-page";
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.clear();

    this.Email = new FormControl(null);
    this.Password = new FormControl(null);

    this.myForm = this._fb.group({
      Email: this.Email,
      Password: this.Password
    });
  }

  login() {

    this.auth.login(this.myForm.value).subscribe(Response => {
      if (Response.status) {
        this.router.navigate(['/dashboard']);
      }
      else {
        this.errorMessage = Response.message;
      }
    });
  }

}
