import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RegisterService } from '../../services/Index.Service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {
  errors: string[];

  public myForm: FormGroup;
  ID: FormControl;
  UserTypeID: FormControl;
  Name: FormControl;
  Email: FormControl;
  Phone: FormControl;
  Password: FormControl;
  constructor(private router: Router, private _fb: FormBuilder, private registerService: RegisterService) { }

  ngOnInit() {
    document.getElementsByTagName('body')[0].className = "hold-transition register-page";

    this.ID = new FormControl(0);
    this.UserTypeID = new FormControl(1);
    this.Name = new FormControl(null, [Validators.required]);
    this.Email = new FormControl(null, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]);
    this.Password = new FormControl(null, [Validators.required, Validators.minLength(6)]);
    this.Phone = new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]);

    this.myForm = this._fb.group({
      ID: this.ID,
      UserTypeID: this.UserTypeID,
      Name: this.Name,
      Email: this.Email,
      Password: this.Password,
      Phone: this.Phone
    });
  }

  save() {
    this.registerService.Add(this.myForm.value).subscribe((Response) => {
      if (Response.status) {
        this.router.navigate(['/login']);
      }
    }, (err) => {
      if (err.status === 400) {
        // handle validation error
        let validationErrorDictionary = JSON.parse(err.text());
        for (var fieldName in validationErrorDictionary) {
          if (validationErrorDictionary.hasOwnProperty(fieldName)) {
            this.errors.push(validationErrorDictionary[fieldName]);
          }
        }
      } else {
        this.errors.push("something went wrong!");
      }
    });
  }

}
