import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { RouterModule } from '@angular/router';
import { MagzineRoutes } from './magzine.route';
import { HttpClientModule } from '@angular/common/http';
import { AddComponent } from './add/add.component';
import { MagzineService } from '../../../services/Index.Service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    DataTablesModule,
    RouterModule.forChild(MagzineRoutes)
  ],
  declarations: [
    IndexComponent,
    AddComponent
  ], 
  providers:[
    MagzineService
  ]
})
export class MagzineModule { }
