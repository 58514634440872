import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthenticationService {

  rootPath: string;
  constructor(private http: HttpClient) {
    this.rootPath = 'api/Account/';
  }

  login(model: any) {
    if (model.Email != '' && model.Email != null && model.Password != '' && model.Password != null) {
      return this.http.post<any>(this.rootPath + "login?Email=" + model.Email + "&password=" + model.Password, null).map(response => {
        if (response && response.status) {
          localStorage.setItem('token', JSON.stringify(response.token));
          localStorage.setItem('user', JSON.stringify(response.info));
        }
        return response;
      });
    }
  }

  forgot(mail: string) { 
    return this.http.post<any>(this.rootPath + "forgot?Email=" + mail, null);
  }

  forgotpassword(model: any) {
    return this.http.post<any>(this.rootPath + "Forgotpassword", model);
  }

}
