export * from './datatable.service';
export * from './dropdown.service'
export * from './user.service';
export * from './register.service';
export * from './Authenticaion.Service';
export * from './customer.service';
export * from './magzine.service';
export * from './entry.service';
export * from './bill.service';
export * from './payment.service';
export * from './setting.service';
export * from './dashboard.service';
export * from './document.service';
export * from './contact.service';
