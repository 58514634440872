import { Routes } from "@angular/router";
import { DefaultAdminComponent } from "../layout/default-admin/default-admin.component";
import { AuthGuard } from "../../guards/auth.guard";
import { ProfileComponent } from './profile/profile.component';
import { SettingComponent } from './setting/setting.component';

export const UserRoutes: Routes = [
  {path: '', component: DefaultAdminComponent, canActivate: [AuthGuard],
  children: [
    {path: 'profile', component: ProfileComponent},
    {path: 'setting', component: SettingComponent},
  ]}
]
