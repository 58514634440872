import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  name: string;
  email: string;
  constructor() { }

  ngOnInit() {
    var user = JSON.parse(localStorage.user);
    this.name = user.name;
    this.email = user.email;
  }

}
