import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BillService, DocumentService } from '../../../services/Index.Service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {

  modellist: any = [];
  month: any;
  year: any;
  id: any;
  message : string = 'Data Lodding  ...'
  constructor(private route: ActivatedRoute, private billservice: BillService, private documentService: DocumentService) { }

  ngOnInit() {
    let params = this.route.snapshot.params;
    this.id = params['id'];
    this.month = params["month"];
    this.year = params["year"];
    if (this.id != undefined && this.id > "0") {
      this.get(this.id);
    }
    else if (this.month != undefined && this.year != undefined) {
      this.printaAll(this.year, this.month);
    }
  }

  get(id: number) {
    this.billservice.Detail(id).subscribe(Response => {
      if (Response.status) {
        this.modellist = Response.data;
      }
      this.message = 'Oops! Data not found.';
    }, error => {
    });
  }

  printaAll(year: number, month: number) {
    this.billservice.multipleDetail(year, month).subscribe(Response => {
      if (Response.status) {
        this.modellist = Response.data;
      }
      this.message = 'Oops! Data not found.';
    }, error => {
    });
  }

  print(): void {
    window.print();
  }

  exportPdf() {
    if (this.id != undefined && this.id > "0") {
      this.documentService.ExportPdfByBillId(this.id);
    }
    else {
      this.documentService.ExportPdf(this.year, this.month);
    }
  }

}
