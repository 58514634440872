import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DataTableService {
  header: any;
  token: string;
  constructor(private http: HttpClient) {
    this.token = JSON.parse(localStorage.getItem('token'));
    const headerSettings: { [name: string]: string | string[]; } = {};
    headerSettings['Authorization'] = 'Bearer ' + this.token;
    headerSettings['Content-Type'] = 'application/json';
    this.header = new HttpHeaders(headerSettings);
  }

  filterTable(datatableElement: any, search: any): void {
    datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      var inputValues = search;
      dtInstance.columns().every(function (index) {
        const that = this;
        var thisindex = that["context"][0].aoColumns[index];
        if (inputValues[thisindex.data] != undefined) {
          that.search("@#$" + inputValues[thisindex.data]);
          thisindex.sName = "@#$" + (inputValues["ft_" + thisindex.data]);
        }
        else {
          that.search("");
          thisindex.sName = "";
        }
      });
      dtInstance.draw();
    });
  }

  searchResults(gridColumns: any, ctrlName: string, thats: any, orderby?: any): any {
    const that = this;
    var rootPath = 'api/' + ctrlName + '/';
    return {
      dom: 'ltip',
      // dom: 'lBtip',
      // buttons: ['copy','print','excel','pdf','csv'],
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      //responsive: true,
      //searching : false,
      order: orderby == undefined ? [[0, "desc"]] : orderby,
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<any>(
            rootPath + "datatable", dataTablesParameters, { headers: this.header }
          ).subscribe(resp => {
            this.fillResult({ that: thats, data: resp.data });
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          }, error => {
            if (error.status == 401) {
              console.log(error);
            }
          });
      },
      columns: gridColumns
    };
  }

  fillResult(val) {
    val.that.result = val.data;
    //this._callservice.DTList(val.data)
  }
}

