import { Component, OnInit, Renderer } from '@angular/core';
import { DataTableService, MagzineService } from '../../../../services/Index.Service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html'
})
export class IndexComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  constructor(private datatable: DataTableService, private renderer: Renderer, private router: Router, private magzineService: MagzineService) { }

  ngOnInit(): void {
    this.dtOptions = this.datatable.searchResults([
      { data: 'Id', title: 'ID', visible: false, },
      { data: 'Name', title: 'Name' },
      { data: 'TypeName', title: 'Type'},
      { data: 'PerUnitAmount', title: 'Amount' },
      // { data: 'BillType', title: 'BillType' },
      // { data: 'Amount', title: 'Amount' },
      { data: 'IsEnable', title: 'IsEnable' },
      { title: 'Action' }],
      'Magzine', this);
  }

  changeStatus(id, event) {
    if (confirm("Are you sure to Change")) {
      this.magzineService.ChangeStatus(id, event.currentTarget.checked).subscribe(Response => {
        if (Response.status) {
        }
      }, error => { });
    }
    else {
      event.currentTarget.checked = !event.currentTarget.checked;
    }
  }
}
