import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableService, CustomerService } from '../../../services/Index.Service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html'
})
export class CustomerComponent implements OnInit {
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  search: any = [];
  constructor(private datatable: DataTableService, private customerService: CustomerService) {
    this.fninit();
  }

  ngOnInit(): void {
    this.dtOptions = this.datatable.searchResults([
      { data: 'Id', title: 'ID', visible: false, },
      { data: 'Name', title: 'Name' },
      { data: 'Phone', title: 'Phone' },
      { data: 'Pincode', title: 'Pincode' },
      { data: 'Village', title: 'Village' },
      { data: 'Address', title: 'Address' },
      { data: 'IsEnable', title: 'IsEnable' },
      { title: 'Action' }],
      'Customer', this);
  }

  changeStatus(id, event) {
    if (confirm("Are you sure to Change")) {
      this.customerService.ChangeStatus(id, event.currentTarget.checked).subscribe(Response => {
        if (Response.status) {
        }
      }, error => { });
    }
    else {
      event.currentTarget.checked = !event.currentTarget.checked;
    }
  }

  fninit() {
    this.search.ft_Name = "contains";
    this.search.Name = "";
    this.search.ft_Phone = "contains";
    this.search.Phone = "";
    this.search.ft_Village = "contains";
    this.search.Village = "";
    this.search.ft_IsEnable = "=";
    this.search.IsEnable = "";
  }

  filter() {
    this.datatable.filterTable(this.datatableElement, this.search)
  }

  reset() {
    this.search.EntryDate = "";
    this.search.Phone = "";
    this.search.Village = "";
    this.datatable.filterTable(this.datatableElement, this.search);
  }
}
