import { Routes } from "@angular/router";
import { DefaultAdminComponent } from "../layout/default-admin/default-admin.component";
import { AuthGuard } from "../../guards/auth.guard";
import { IndexComponent } from "./index/index.component";
import { PayComponent } from './pay/pay.component';

export const PaymentRoutes: Routes = [
  {
    path: '', component: DefaultAdminComponent, canActivate: [AuthGuard],
    children: [
      { path: 'payment', component: IndexComponent },
      { path: 'payment/pay/:id', component: PayComponent },
    ]
  }
]
