import { Routes } from "@angular/router";
import { DefaultAdminComponent } from "../layout/default-admin/default-admin.component";
import { AuthGuard } from "../../guards/auth.guard";
import { IndexComponent } from "./index/index.component";
import { PreviewComponent } from "./preview/preview.component";

export const BillRoutes: Routes = [
  {
    path: '', component: DefaultAdminComponent, canActivate: [AuthGuard],
    children: [
      { path: 'bill', component: IndexComponent },
      { path: 'bill/preview/:id', component: PreviewComponent },
      { path: 'bill/preview/:year/:month', component: PreviewComponent },
    ]
  }
]
