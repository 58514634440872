import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableService, BillService } from '../../../services/Index.Service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html'
})
export class IndexComponent implements OnInit {
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  search: any = [];
  ddmonth: any = [];
  ddyear: any = [];
  month: any;
  year: any;
  constructor(private datatable: DataTableService, private billservice: BillService) {
  }

  ngOnInit(): void {
    this.fndropdown();
    this.dtOptions = this.datatable.searchResults([
      { data: 'Id', title: 'ID', visible: false },
      { data: 'UserId', title: 'User id', visible: false },
      { data: 'CustomerId', title: 'Customer id', visible: false },
      { data: 'CustomerName', title: 'Customer Name' },
      { data: 'Month', title: 'Month' },
      { data: 'Year', title: 'Year' },
      { title: 'InvoiceNo' },
      { data: 'RemainingAmount', title: 'Remaining Amount' },
      { data: 'Amount', title: 'Bill Amount' },
      { title: 'Total Amount' },
      { title: 'Action' }
    ],
      'Bill', this, [[2, "desc"], [6, "desc"]]);
  }

  filter() {
    this.search.ft_Month = "=";
    this.search.ft_Year = "=";
    this.search.Month = this.month;
    this.search.Year = this.year;
    this.datatable.filterTable(this.datatableElement, this.search);
  }

  fndropdown() {
    this.month = new Date().getMonth() + 1;
    for (var i = 1; i <= 12; i++) {
      this.ddmonth.push(i);
    }

    this.year = new Date().getFullYear();
    for (var i = 2019; i <= this.year; i++) {
      this.ddyear.push(i);
    }
  }

  ganrateAll() {
    if (this.month >= (new Date().getMonth() + 1) && this.year >= new Date().getFullYear()) {
      alert("This month bill not ganrated");
    }
    else {
      if (confirm("Check first your all this month entries are corrct")) {
        this.billservice.Ganrate(this.month, this.year).subscribe(Response => {
          if (Response.status) {
            this.datatable.filterTable(this.datatableElement, this.search);
          }
        });
      }
    }
  }
}
