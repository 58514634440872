import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { RouterModule } from '@angular/router';
import { PaymentRoutes } from './payment.route';
import { PaymentService } from '../../services/Index.Service';
import { PayComponent } from './pay/pay.component';

@NgModule({
  declarations: [
    IndexComponent,
    PayComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    DataTablesModule,
    RouterModule.forChild(PaymentRoutes)
  ],
  providers:[PaymentService]
})
export class PaymentModule { }
