import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableService, EntryService, DropdownService } from '../../../services/Index.Service';
import { DataTableDirective } from 'angular-datatables';
import { AlertPromise } from 'selenium-webdriver';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html'
})
export class IndexComponent implements OnInit {
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  search: any = [];
  ddCustomer: any = [];
  constructor(private datatable: DataTableService, private entryservice: EntryService,
    private dropdownService: DropdownService) {
    this.fninit();
  }

  ngOnInit(): void {
    this.dtOptions = this.datatable.searchResults([
      { data: 'Id', title: 'ID', visible: false },
      { data: 'UserId', title: 'User id', visible: false },
      { data: 'CustomerId', title: 'Customer id', visible: false },
      { data: 'MagzineId', title: 'Magzine id', visible: false },
      { data: 'UserName', title: 'User Name' },
      { data: 'CustomerName', title: 'Customer Name' },
      { data: 'MagzineName', title: 'Magzine Name' },
      { data: 'EntryDate', title: 'Entry Date' },
      { data: 'Amount', title: 'Amount' },
      { data: 'NoOfUnit', title: 'NoOfUnit' },
      { data: 'IsEnable', title: 'IsEnable' },
      //{ title: 'Action' }
    ],
      'MagzineEntry', this);
  }

  changeStatus(id, event) {
    if (confirm("Are you sure to Change")) {
      this.entryservice.ChangeStatus(id, event.currentTarget.checked).subscribe(Response => {
        if (Response.status) {
        }
      }, error => { });
    }
    else {
      event.currentTarget.checked = !event.currentTarget.checked;
    }
  }

  fninit() {
    this.search.ft_EntryDate = "=";
    this.search.EntryDate = "";
    this.search.ft_CustomerId = "=";
    this.search.CustomerId = "";

    this.dropdownService.Get("Customer").subscribe(Response => {
      if (Response.status) {
        this.ddCustomer = Response.data;
      }
    });
  }

  filter() {
    this.datatable.filterTable(this.datatableElement, this.search)
  }

  reset() {
    this.search.EntryDate = "";
    this.search.CustomerId = "";
    this.datatable.filterTable(this.datatableElement, this.search);
  }
}
