import { Component, OnInit} from '@angular/core';
import { AuthenticationService } from '../../services/Index.Service';
import { Router, ActivatedRoute } from '@angular/router';
import { fail } from 'assert';
import { debug } from 'util';
import { transform } from 'typescript';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html'
})
export class ForgotPasswordComponent implements OnInit {
  email: string;
  errorMessage: string;
  successMessage: string;
  refid: string;
  password: string;
  otp: string;
  confirmPassword: string;
  isforgotpage: boolean = false;
  isinvalidcompair: boolean = false;
  constructor(private router: Router, private route: ActivatedRoute, private auth: AuthenticationService) {
    let refid = route.snapshot.params['id'];
    let otp = route.snapshot.params['otp'];
    if (refid != undefined) {
      this.isforgotpage = true;
      this.refid = refid;
      this.otp = otp;
    }
  }

  ngOnInit() {
    document.getElementsByTagName('body')[0].className = "hold-transition login-page";
  }

  forgot(form) {
    this.auth.forgot(form.form.value.email).subscribe(Response => {
      if (Response.status) {
        this.isforgotpage = true;
        this.refid = Response.refId;
        this.successMessage = Response.message;
      }
      else {
        this.errorMessage = Response.message;
      }
    });
  }

  forgotpassword(form) {
    this.auth.forgotpassword(form).subscribe(Response => {
      if (Response.status) {
        this.router.navigate(['/login']);
      }
      else {
        this.errorMessage = Response.message;
      }
    });
  }

  confirmpassword(form: any) {
    this.isinvalidcompair = (form.password != form.confirmPassword);
  }

}
