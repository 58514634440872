import { Routes } from "@angular/router";
import { AddComponent } from "./add/add.component";
import { CustomerComponent } from "./customer.component";
import { DefaultAdminComponent } from "../../layout/default-admin/default-admin.component";
import { AuthGuard } from "../../../guards/auth.guard";

export const CustomerRoutes: Routes = [
  {
    path: '', component: DefaultAdminComponent, canActivate: [AuthGuard],
    children: [
      { path: 'customer', component: CustomerComponent },
      { path: 'customer/add', component: AddComponent },
      { path: 'customer/:id', component: AddComponent },
    ]
  }
]
