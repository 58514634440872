import { Routes } from "@angular/router";
import { DefaultAdminComponent } from "../layout/default-admin/default-admin.component";
import { AuthGuard } from "../../guards/auth.guard";
import { IndexComponent } from "./index/index.component";
import { AddComponent } from "./add/add.component";

export const EntryRoutes: Routes = [
  {
    path: '', component: DefaultAdminComponent, canActivate: [AuthGuard],
    children: [
      { path: 'entry', component: IndexComponent },
      { path: 'entry/add', component: AddComponent },
      { path: 'entry/:id', component: AddComponent },
    ]
  }
]
