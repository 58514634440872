import { Component, OnInit } from '@angular/core';
import { DropdownService, EntryService } from '../../../services/Index.Service';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
//declare var startup: any;

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html'
})
export class AddComponent implements OnInit {

  public myForm: any;
  Id: FormControl;
  CustomerId: FormControl;
  MagzineListId: FormControl;
  MagzineId: FormControl;
  EntryDate: FormControl;
  IsEnable: FormControl;

  ddMagzine: any = [];
  constructor(private router: Router, private _fb: FormBuilder, private dropdownService: DropdownService, private entryService: EntryService) { }

  ngOnInit() {
    //startup();
    this.setForm(null);
    this.fndropdown();
  }

  setForm(model: any) {
    this.Id = new FormControl(0);
    this.CustomerId = new FormControl(0);
    this.MagzineId = new FormControl(0);
    this.MagzineListId = new FormControl('');
    this.IsEnable = new FormControl(true);
    this.EntryDate = new FormControl(new Date(), [Validators.required]);

    this.myForm = this._fb.group({
      Id: this.Id,
      CustomerId: this.CustomerId,
      MagzineId: this.MagzineId,
      MagzineListId: this.MagzineListId,
      IsEnable: this.IsEnable,
      EntryDate: this.EntryDate
    });
  }

  fndropdown() {
    this.dropdownService.Get("Magzine").subscribe(Response => {
      if (Response.status) {
        this.ddMagzine = Response.data;
      }
    });
  }

  save() {
    debugger
    this.entryService.Manage(this.myForm.value).subscribe(Response => {
      if (Response.status) {
        this.router.navigate(['/entry']);
      }
    });
  }
}
