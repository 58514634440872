import { Routes } from "@angular/router";
import { AuthGuard } from "./guards/index.service";

import { DefaultAdminComponent } from './admin/layout/default-admin/default-admin.component';
import { LoginComponent } from "./authentication/login/login.component";
import { DashboardComponent } from "./admin/dashboard/dashboard.component";
import { RegisterComponent } from "./front/register/register.component";
import { CustomerModule } from "./admin/masters/customer/customer.module";
import { MagzineModule } from "./admin/masters/magzine/magzine.module";
import { EntryModule } from "./admin/entry/entry.module";
import { BillModule } from "./admin/bill/bill.module";
import { HomeComponent } from './front/Home/home.component';
import { ForgotPasswordComponent } from './authentication/forgotpassword/forgotpassword.component';
import { HowToUseComponent } from './front/HowToUse/howtouse.component';
import { DefaultFrontComponent } from './front/layout/default-front/default-front.component';
import { PrivacyPolicyComponent } from './front/privacypolicy/privacypolicy.component';
import { TermsOfServiceComponent } from './front/termsofservice/termsofservice.component';

export const AppRoutes: Routes = [
  {
    path: '', component: DefaultAdminComponent, canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'magzine', component: MagzineModule },
      { path: 'customer', component: CustomerModule },
      { path: 'entry', component: EntryModule },
      { path: 'bill', component: BillModule }
    ]
  },

  {
    path: '', component: DefaultFrontComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'forgotpassword', component: ForgotPasswordComponent },
      { path: 'forgotpassword/:id/:otp', component: ForgotPasswordComponent },
      { path: 'home', component: HomeComponent },
      { path: 'how_to_use', component: HowToUseComponent },
      { path: 'privacy_policy', component: PrivacyPolicyComponent },
      { path: 'terms_of_service', component: TermsOfServiceComponent }
    ]
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
] 
