import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RegisterService {

  rootPath: string;
  constructor(private http: HttpClient) {
    this.rootPath = 'api/Account/';
  }

  Add(model: any) {
    return this.http.post<any>(this.rootPath + "Add", model);
  }


}
