import { Routes } from "@angular/router";
import { DefaultAdminComponent } from "../../layout/default-admin/default-admin.component";
import { AuthGuard } from "../../../guards/auth.guard";
import { IndexComponent } from "./index/index.component";
import { AddComponent } from "./add/add.component";

export const MagzineRoutes: Routes = [
  {
    path: '', component: DefaultAdminComponent, canActivate: [AuthGuard],
    children: [
      { path: 'magazine', component: IndexComponent },
      { path: 'magazine/add', component: AddComponent },
      { path: 'magazine/:id', component: AddComponent },
    ]
  }
]
