import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-howtouse',
  templateUrl: 'howtouse.component.html'
})
export class HowToUseComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
