import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService, DropdownService } from '../../../../services/Index.Service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html'
})
export class AddComponent implements OnInit {
  public myForm: any;
  ID: FormControl;
  Name: FormControl;
  Address: FormControl;
  Phone: FormControl;
  Pincode: FormControl;
  Village: FormControl;
  CustomerMagzineId: FormControl;
  CustomerId: FormControl;
  MagzineId: FormControl;
  NoOfUnit: FormControl;
  IsEnable: FormControl;
  BillAmount: FormControl;
  ddMagzine: any = [];
  constructor(private router: Router, private route: ActivatedRoute, private _fb: FormBuilder, private customerService: CustomerService,
    private dropdownService: DropdownService) {
    let id = route.snapshot.params['id'];
    if (id > "0")
      this.get(id);
    else
      this.setForm(null);
  }

  ngOnInit() {
    this.fndropdown()
  }

  save() {
    this.customerService.Manage(this.myForm.value).subscribe(Response => {
      if (Response.status) {
        this.router.navigate(['/customer']);
      }
    });
  }

  get(id: number) {
    this.customerService.Get(id).subscribe(Response => {
      if (Response.status) {
        this.setForm(Response.data);
        Response.data.customerMagzine.forEach(element => {
          const control = <FormArray>this.myForm.controls['CustomerMagzine'];
          control.push(this.initItem(element));
        });
      }
    }, error => {
    });
  }

  setForm(model: any) {
    this.ID = new FormControl(!model ? 0 : model.id);
    this.Name = new FormControl(!model ? null : model.name, [Validators.required]);
    this.Address = new FormControl(!model ? null : model.address, [Validators.required]);
    this.Phone = new FormControl(!model ? null : model.phone, [Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]);//new FormControl(!model ? null : model.phone, [Validators.pattern(/^[6-9]\d{9}$/)]);
    this.Pincode = new FormControl(!model ? null : model.pinCode, [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]); //new FormControl(!model ? null : model.pinCode, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]);
    this.Village = new FormControl(!model ? null : model.village, [Validators.required]);
    this.BillAmount = new FormControl(!model ? 0 : model.billAmount, [Validators.required])

    this.myForm = this._fb.group({
      ID: this.ID,
      Name: this.Name,
      Phone: this.Phone,
      Address: this.Address,
      Pincode: this.Pincode,
      Village: this.Village,
      BillAmount: this.BillAmount,
      CustomerMagzine: this._fb.array(!model ? [this.initItem(null)] : [])
    });
  }

  initItem(nmodel: any) {
    this.CustomerMagzineId = new FormControl(!nmodel ? 0 : nmodel.id);
    //this.CustomerId = new FormControl(!nmodel ? 0 : nmodel.customerId);
    this.MagzineId = new FormControl(!nmodel ? '' : nmodel.magzineId, [Validators.required]);
    this.NoOfUnit = new FormControl(!nmodel ? '' : nmodel.noOfUnit, [Validators.required, Validators.min(1)]);
    this.IsEnable = new FormControl(!nmodel ? true : nmodel.isEnable);

    return this._fb.group({
      Id: this.CustomerMagzineId,
      //CustomerId: this.CustomerId,
      MagzineId: this.MagzineId,
      NoOfUnit: this.NoOfUnit,
      IsEnable: this.IsEnable,
    });
  }

  addItem() {
    const control = <FormArray>this.myForm.controls['CustomerMagzine'];
    control.push(this.initItem(null));
  }

  removeItem(i: number) {
    const control = <FormArray>this.myForm.controls['CustomerMagzine'];
    control.removeAt(i);
  }

  fndropdown() {
    this.dropdownService.Get("Magzine").subscribe(Response => {
      if (Response.status) {
        this.ddMagzine = Response.data;
      }
    });
  }
}
