import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CustomerService {
  header: any;
  token: string;
  rootPath: string;
  constructor(private http: HttpClient) {
    this.rootPath = 'api/Customer/';

    this.token = JSON.parse(localStorage.getItem('token'));
    const headerSettings: { [name: string]: string | string[]; } = {};
    headerSettings['Authorization'] = 'Bearer ' + this.token;
    headerSettings['Content-Type'] = 'application/json';
    this.header = new HttpHeaders(headerSettings);
  }

  Manage(model: any) {
    return this.http.post<any>(this.rootPath, model, { headers: this.header });
  }

  Update(model: any) {
    return this.http.post<any>(this.rootPath, model, { headers: this.header });
  }

  Get(id: any) {
    return this.http.get<any>(this.rootPath + id, { headers: this.header });
  }

  ChangeStatus(id: number, isEnable: boolean) {
    return this.http.post<any>(this.rootPath + "ChangeStatus?id=" + id + "&isEnable=" + isEnable, { headers: this.header });
  }

  ////Exports() {
  ////  return this.http.get<any>(this.rootPath + "Export", { headers: this.header });

  ////  //this.http.get(this.rootPath + "Export", {headers: this.header})
  ////  //  .subscribe(response => response.blob())
  ////  //  .then(blob => URL.createObjectURL(blob))
  ////  //  .then(url => {
  ////  //    window.open(url, '_blank');
  ////  //  });
  ////}
}
