import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DropdownService, MagzineService } from '../../../../services/Index.Service';
import { validateStyleProperty } from '@angular/animations/browser/src/render/shared';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html'
})
export class AddComponent implements OnInit {
  public myForm: FormGroup;
  ID: FormControl;
  Name: FormControl;
  TypeId: FormControl;
  PerUnitAmount: FormControl;

  WeekAmountId: FormControl;
  MagzineId: FormControl;
  Sunday: FormControl;
  Monday: FormControl;
  Tuesday: FormControl;
  Wednesday: FormControl;
  Thursday: FormControl;
  Friday: FormControl;
  Saturday: FormControl;

  //BillType: FormControl;
  //Amount: FormControl;
  //IsEnable: FormControl;

  ddMagzineType: any = [];
  WeeklyAmount: any = {};
  isShowWeekDay: boolean = false;
  constructor(private router: Router, private route: ActivatedRoute, private _fb: FormBuilder,
    private magzineService: MagzineService, private dropdownService: DropdownService) {
    let id = route.snapshot.params['id'];
    if (id > "0")
      this.getData(id);
    else
      this.setForm(null);
  }


  ngOnInit() {
    this.fndropdown();
  }

  save() {
    var data = this.myForm.value;
    this.WeeklyAmount = {
      Id: data.WeekAmountId,
      MagzineId: data.MagzineId,
      Sunday: data.Sunday,
      Monday: data.Monday,
      Tuesday: data.Tuesday,
      Wednesday: data.Wednesday,
      Thursday: data.Thursday,
      Friday: data.Friday,
      Saturday: data.Saturday
    };
    data.WeeklyAmount = this.WeeklyAmount;
    this.magzineService.Manage(this.myForm.value).subscribe(Response => {
      if (Response.status) {
        this.router.navigate(['/magazine']);
      }
      else {
        alert(Response.message);
      }
    });
  }

  fndropdown() {
    this.dropdownService.Get("MagzineType").subscribe(Response => {
      if (Response.status) {
        this.ddMagzineType = Response.data;
      }
    });
  }

  getData(id: number) {
    this.magzineService.Get(id).subscribe(Response => {
      if (Response.status) {
        this.setForm(Response.data);
      }
    });
  }

  setForm(model: any) {
    this.ID = new FormControl(!model ? 0 : model.id);
    this.Name = new FormControl(!model ? null : model.name, [Validators.required]);
    this.TypeId = new FormControl(!model ? "" : model.typeId, [Validators.required]);
    this.PerUnitAmount = new FormControl(!model ? null : model.perUnitAmount);
    this.WeekAmountId = new FormControl(!model || !model.weeklyAmount ? 0 : model.weeklyAmount.id);
    this.MagzineId = new FormControl(!model || !model.weeklyAmount ? 0 : model.weeklyAmount.magzineId);
    this.Sunday = new FormControl(!model || !model.weeklyAmount ? null : model.weeklyAmount.sunday);
    this.Monday = new FormControl(!model || !model.weeklyAmount ? null : model.weeklyAmount.monday);
    this.Tuesday = new FormControl(!model || !model.weeklyAmount ? null : model.weeklyAmount.tuesday);
    this.Wednesday = new FormControl(!model || !model.weeklyAmount ? null : model.weeklyAmount.wednesday);
    this.Thursday = new FormControl(!model || !model.weeklyAmount ? null : model.weeklyAmount.thursday);
    this.Friday = new FormControl(!model || !model.weeklyAmount ? null : model.weeklyAmount.friday);
    this.Saturday = new FormControl(!model || !model.weeklyAmount ? null : model.weeklyAmount.saturday);
    //this.BillType = new FormControl(!model ? null : model.billType, [Validators.required]);
    //this.Amount = new FormControl(!model ? null : model.amount, [Validators.required]);
    //this.IsEnable = new FormControl(!model ? false : model.isEnable, [Validators.required]);

    this.myForm = this._fb.group({
      ID: this.ID,
      Name: this.Name,
      TypeId: this.TypeId,
      PerUnitAmount: this.PerUnitAmount,
      WeekAmountId: this.WeekAmountId,
      MagzineId: this.MagzineId,
      Sunday: this.Sunday,
      Monday: this.Monday,
      Tuesday: this.Tuesday,
      Wednesday: this.Wednesday,
      Thursday: this.Thursday,
      Friday: this.Friday,
      Saturday: this.Saturday,
      //BillType: this.BillType,
      //Amount: this.Amount,
      //IsEnable: this.IsEnable
    });
  }
}

