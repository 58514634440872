import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app-route';
import { AuthGuard } from './guards/index.service';
import 'rxjs/add/operator/map';
import { DataTablesModule } from 'angular-datatables';

import {
  DataTableService, RegisterService, UserService, AuthenticationService, DropdownService, DashboardService, DocumentService,
  ContactService
} from './services/Index.Service';

import { AppComponent } from './app.component';
import { LoginComponent } from './authentication/login/login.component';
import { HeaderComponent } from './admin/layout/header/header.component';
import { FooterComponent } from './admin/layout/footer/footer.component';
import { SideMenuComponent } from './admin/layout/side-menu/side-menu.component';
import { DefaultAdminComponent } from './admin/layout/default-admin/default-admin.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { RegisterComponent } from './front/register/register.component';
import { HomeComponent } from './front/Home/home.component';
import { ForgotPasswordComponent } from './authentication/forgotpassword/forgotpassword.component';

import { CustomerModule } from './admin/masters/customer/customer.module';
import { MagzineModule } from './admin/masters/magzine/magzine.module';
import { EntryModule } from './admin/entry/entry.module';
import { BillModule } from './admin/bill/bill.module';
import { PaymentModule } from './admin/payment/payment.module';
import { UserModule } from './admin/user/user.module';
import { HowToUseComponent } from './front/HowToUse/howtouse.component';
import { DefaultFrontComponent } from './front/layout/default-front/default-front.component';
import { frontFooterComponent } from './front/layout/footer/front-footer.component';
import { FrontHeaderComponent } from './front/layout/front-header/front-header.component';
import { ContactComponent } from './front/contact/contact.component';
import { PrivacyPolicyComponent } from './front/privacypolicy/privacypolicy.component';
import { TermsOfServiceComponent } from './front/termsofservice/termsofservice.component';

@NgModule({
  declarations: [
    AppComponent,
    DefaultAdminComponent,
    DefaultFrontComponent,
    HeaderComponent,
    SideMenuComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ForgotPasswordComponent,
    HowToUseComponent, frontFooterComponent, FrontHeaderComponent, ContactComponent,
    PrivacyPolicyComponent, TermsOfServiceComponent
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    DataTablesModule,
    CustomerModule,
    MagzineModule,
    EntryModule,
    BillModule,
    PaymentModule,
    UserModule,
    RouterModule.forRoot(AppRoutes),
  ],
  providers: [
    AuthGuard,
    ContactService,
    DataTableService, DropdownService,
    RegisterService, UserService, AuthenticationService, DashboardService, DocumentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
