import { NgModule } from '@angular/core';
import { AddComponent } from './add/add.component';
import { CustomerRoutes } from './customer.route';
import { RouterModule } from '@angular/router';
import { CustomerComponent } from './customer.component';
import { CustomerService } from '../../../services/Index.Service';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [
      AddComponent,
      CustomerComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    DataTablesModule,
    RouterModule.forChild(CustomerRoutes)
  ],
  providers: [
    CustomerService
  ]
})
export class CustomerModule { }
