import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {
  idname: string = 'errormessage';
  status: boolean = false;
  message: string;
  public myForm: FormGroup;
  ID: FormControl;
  Name: FormControl;
  Email: FormControl;
  Subject: FormControl;
  Message: FormControl;
  constructor(private _fb: FormBuilder,private contact : ContactService) { }

  ngOnInit() {

    this.ID = new FormControl(0);
    this.Name = new FormControl(null, [Validators.required, Validators.minLength(3)]);
    this.Email = new FormControl(null, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]);
    this.Subject = new FormControl(null, [Validators.required, Validators.minLength(10)]);
    this.Message = new FormControl(null, [Validators.required, Validators.minLength(50), Validators.maxLength(500)]);

    this.myForm = this._fb.group({
      ID: this.ID,
      Name: this.Name,
      Email: this.Email,
      Subject: this.Subject,
      Message: this.Message
    });
  }

  send() {
    this.contact.Add(this.myForm.value).subscribe((Response) => {
      this.status = Response.status;
      this.idname = Response.status?"sendmessage": "errormessage"; 
        this.message = Response.message;
    });
  }
}
