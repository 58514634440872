import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ContactService {

  rootPath: string;
  constructor(private http: HttpClient) {
    this.rootPath = 'api/Contact/';
  }

  Add(model: any) {
    return this.http.post<any>(this.rootPath + "Insert", model);
  }


}
