import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataTableService, PaymentService, DocumentService } from '../../../services/Index.Service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html'
})
export class IndexComponent implements OnInit {
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  payment: any = {};
  search: any = [];
  name: any;
  pincode: any;
  constructor(private datatable: DataTableService, private documentService: DocumentService, private paymentService: PaymentService) { }

  ngOnInit(): void {
    this.dtOptions = this.datatable.searchResults([
      { data: 'Id', title: 'ID', visible: false, },
      { data: 'Name', title: 'Name' },
      { data: 'Phone', title: 'Phone' },
      { data: 'PinCode', title: 'Pincode' },
      { data: 'Village', title: 'Village' },
      { data: 'Address', title: 'Address' },
      { data: 'BillAmount', title: 'Bill Amount' },
      { data: 'Amount', title: 'Receive Amount' },
      { title: 'Action' }],
      'Customer', this);
  }

  save(item: any, amount: any) {
    if (amount.value != 0 && amount.value != '') {
      this.payment.CustomerId = item.id;
      this.payment.Amount = amount.value;
      this.paymentService.Manage(this.payment).subscribe(Response => {
        if (Response.status) {
          item.billAmount -= this.payment.Amount;
          amount.value = '';
          alert('Payment Successfully Received.');
        }
      });
    } else {
      alert("Amount Not Blank and Zero.")
    }
  }

  filter() {
    this.search.ft_Name = "contains";
    this.search.ft_PinCode = "contains";
    this.search.Name = this.name;
    this.search.PinCode = this.pincode;
    this.datatable.filterTable(this.datatableElement, this.search);
  }

  export() {
    this.documentService.ExportXlsx();
  }
}
