import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DropdownService {
  header: any;
  token :string;
  rootPath: string;
  constructor(private http: HttpClient) {
    this.rootPath = 'api/dropdown/';

    this.token = JSON.parse(localStorage.getItem('token'));
    const headerSettings: { [name: string]: string | string[]; } = {};
    headerSettings['Authorization'] = 'Bearer ' + this.token;
    headerSettings['Content-Type'] = 'application/json';
    this.header = new HttpHeaders(headerSettings);
  }

  Get(Name: string) {
    return this.http.get<any>(this.rootPath +'/'+ Name, { headers: this.header });
  }
}
