import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService, PaymentService, DataTableService } from '../../../services/Index.Service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html'
})
export class PayComponent implements OnInit {
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  public myForm: any;
  ID: FormControl;
  CustomerId: FormControl;
  Amount: FormControl;
  model: any;
  id: any;
  constructor(private router: Router, private route: ActivatedRoute, private _fb: FormBuilder,
    private customerService: CustomerService, private paymentService: PaymentService, private datatable: DataTableService, ) {
    this.id = route.snapshot.params['id'];
    if (this.id > "0")
      this.get(this.id);
  }

  ngOnInit() {
    this.dtOptions = this.datatable.searchResults([
      { data: 'Id', title: 'Id', visible: false },
      { data: 'Amount', title: 'Amount' },
      { data: 'ReceiveDate', title: 'ReceiveDate' }],
      'Payment/' + this.id, this);
  }

  save() {
    if (this.myForm.value.amount != 0 && this.myForm.value.amount != '') {
      this.paymentService.Manage(this.myForm.value).subscribe(Response => {
        if (Response.status) {
          alert('Payment Successfully Received.');
          this.datatable.filterTable(this.datatableElement, []);
        }
      });
    } else {
      alert("Amount Not Blank and Zero.")
    }
  }

  get(id: number) {
    this.customerService.Get(id).subscribe(Response => {
      if (Response.status) {
        this.model = Response.data;
        this.CustomerId = new FormControl(!this.model ? 0 : this.model.id);
        this.Amount = new FormControl(this.model.billAmount, [Validators.required])
        this.myForm = this._fb.group({
          CustomerId: this.CustomerId,
          Amount: this.Amount,
        });
      }
    }, error => {
    });
  }
}
