import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-front-header',
  templateUrl: './front-header.component.html'
})
export class FrontHeaderComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
     this.route.fragment.subscribe(f => {
      var element = document.querySelector("#" + f)
      if (element) {
        element.scrollTop += document.querySelector("#header").scrollHeight;
        element.scrollIntoView({ behavior: "smooth" });
      }
    });
  }

}
