import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SettingService {
  header: any;
  token: string;
  rootPath: string;
  constructor(private http: HttpClient) {
    this.rootPath = 'api/Setting/';

    this.token = JSON.parse(localStorage.getItem('token'));
    const headerSettings: { [name: string]: string | string[]; } = {};
    headerSettings['Authorization'] = 'Bearer ' + this.token;
    headerSettings['Content-Type'] = 'application/json';
    this.header = new HttpHeaders(headerSettings);
  }

  Manage(model: any) {
    return this.http.post<any>(this.rootPath, model, { headers: this.header });
  }

  Get() {
    return this.http.get<any>(this.rootPath, { headers: this.header });
  }

}
