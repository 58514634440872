import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DocumentService {
  header: any;
  token: string;
  rootPath: string;
  constructor(private http: HttpClient) {
    this.rootPath = 'api/document/';

    this.token = JSON.parse(localStorage.getItem('token'));
    const headerSettings: { [name: string]: string | string[]; } = {};
    headerSettings['Authorization'] = 'Bearer ' + this.token;
    headerSettings['Content-Type'] = 'application/json';
    this.header = new HttpHeaders(headerSettings);
  }

  ExportXlsx() {
    this.http.get(this.rootPath + "Export", { responseType: 'blob', headers: this.header }).subscribe((data: any) => {
      if (data.size > 0) {
        this.downloadFiles(data, "Customer_" + Date.now() + ".xlsx", "application/xlsx");
      }
      else {
        alert("file Not Exists !");
      }
    }, error => {
    });
  }

  ExportPdf(year: number, month: number) {
    this.http.get(this.rootPath + "/billpdf/" + year + "/" + month, { responseType: 'blob', headers: this.header }).subscribe((data: any) => {
      if (data.size > 0) {
        this.downloadFiles(data, "bill_" + year + "_" + month + ".pdf", "application/pdf");
      }
      else {
        alert("file Not Exists !");
      }
    }, error => {
    });
  }

  ExportPdfByBillId(billId: number) {
    this.http.get(this.rootPath + "/billpdf/" + billId, { responseType: 'blob', headers: this.header }).subscribe((data: any) => {
      if (data.size > 0) {
        this.downloadFiles(data, "bill_" + billId + ".pdf", "application/pdf");
      }
      else {
        alert("file Not Exists !");
      }
    }, error => {
    });
  }

  private downloadFiles(data: ArrayBuffer, fileName: string, contentType: string) {
    var blob = new Blob([data], { type: contentType });
    var url = window.URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
