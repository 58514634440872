import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-admin',
  templateUrl: './default-admin.component.html',
  styleUrls: ['./default-admin.component.css']
})
export class DefaultAdminComponent implements OnInit {
   
  constructor() { }

  ngOnInit() {
    document.getElementsByTagName('body')[0].className = "hold-transition skin-blue sidebar-mini";
    if (document.querySelector('.mobile-nav-toggle') != null) {
      document.querySelector('.mobile-nav-toggle').remove();
      
    }
  }
}
